import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MontageVideos = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [videoLimit, setVideoLimit] = useState(0);
  const [templateName, setTemplateName] = useState("template1");

  const API_URL = process.env.REACT_APP_API_HOST;

  const fetchTemplateLimit = async (templateName) => {
    try {
      const response = await axios.get(
        `${API_URL}/getLimitationTemplate.php?templateName=${templateName}`
      );
      const data = response.data;
      if (data && data.maxComment) {
        setVideoLimit(data.maxComment);
      } else {
        console.error("La réponse de l'API est invalide ou manquante.");
        setVideoLimit(0); // ou une valeur par défaut en cas d'erreur
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de la limitation :", error);
      setVideoLimit(0); // ou une valeur par défaut en cas d'erreur
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    const selectedTemplate = document.getElementById("templateName").value;
    if (!selectedTemplate || selectedTemplate === "") {
      alert(
        "Veuillez sélectionner un template avant de procéder à l'importation."
      );
      return;
    }
    const selectedFormat = document.getElementById("format").value;
    if (!selectedFormat || selectedFormat === "") {
      alert(
        "Veuillez sélectionner un format du rendu avant de procéder à l'importation."
      );
      return;
    }

    const formData = new FormData(e.target);
    formData.append("templateName", selectedTemplate);
    formData.append("format", selectedFormat);

    setLoading(true);
    setIsUploadComplete(false);

    try {
      const response = await axios.post(`${API_URL}/upload.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            const percentComplete = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setProgress(percentComplete);
            if (percentComplete === 100) {
              setIsUploadComplete(true);
            }
          }
        },
      });

      function extractFolderName(path) {
        if (typeof path !== "string") {
          console.error(
            "Le chemin fourni n'est pas une chaîne de caractères :",
            path
          );
          return "";
        }
        let folderName = path.replace(/^videos\//, "");
        folderName = folderName.replace(/\/$/, "");
        return folderName;
      }

      const res = response.data;
      if (res.chemin) {
        const folderName = extractFolderName(res.chemin);
        localStorage.setItem("selectedProject", JSON.stringify(res.chemin));
        setFolderName(folderName);
        await handleGenerate(folderName);
        navigate(`/video-editor/${folderName}`);
      } else {
        console.error("Propriété 'chemin' manquante dans la réponse :", res);
        alert("Erreur lors de l'upload." + res.message);
        resetForm();
      }
    } catch (error) {
      console.error("Upload error:", error);
      if (error.response) {
        console.error("Server response:", error.response);
      } else {
        console.error("Error details:", error.message);
      }
      alert("Erreur lors de l'upload. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  const handleGenerate = async (folderName) => {
    setProgress(0);
    setLoading(true);

    try {
      console.warn("folderName = ", folderName);
      localStorage.setItem("selectedProject", folderName);
      const response = await axios.post(`${API_URL}/generate.php`, {
        parentFolderName: folderName,
        templateName: templateName,
      });
      const res = response.data;
      console.warn("res = ", res);
      if (res.status === 1) {
      } else {
        alert(res.message);
      }
    } catch (error) {
      console.error("Generate error:", error);
      alert("Erreur lors de la génération. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setProgress(0);
    setLoading(false);
    setIsUploadComplete(false);
    setFileNames([]);
    setFolderName("");
    setTemplateName("");
    document.getElementById("videoUploadForm").reset();
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const fileNames = files.map((file) => file.name);
    setFileNames(fileNames);
  };

  const handleTemplateChange = async (e) => {
    const selectedTemplate = e.target.value;
    setTemplateName(selectedTemplate);

    // Fetch the video limit from the API
    await fetchTemplateLimit(selectedTemplate);

    console.log(
      `handleTemplateChange : selectedTemplate === ${selectedTemplate}`
    );
    console.log(`Updated videoLimit: ${videoLimit}`);
    console.log(`Updated templateName: ${templateName}`);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center">Montage Videos</h2>
      <div className="row">
        <div className="col-md-6 mx-auto pt-3">
          {loading ? (
            <div id="loading" className="mt-3 text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>Processus en cours...</p>
            </div>
          ) : (
            <form
              id="videoUploadForm"
              onSubmit={handleUpload}
              encType="multipart/form-data"
            >
              <div className="form-group">
                <select
                  className="custom-select rounded-0"
                  id="templateName"
                  name="templateName"
                  onChange={handleTemplateChange}
                >
                  <option value="">Sélectionner un template</option>
                  <option value={"template1"}>Template 1</option>
                  <option value={"template2"}>Template 2</option>
                </select>
                <small className="form-text text-muted">
                  {templateName !== "" &&
                    videoLimit > 0 &&
                    `Vous pouvez télécharger jusqu'à ${videoLimit} vidéos à la fois`}
                </small>
                <br />
                <select
                  className="custom-select rounded-0"
                  id="format"
                  name="format"
                >
                  <option value="576x1024">Format par défaut - 576x1024</option>
                  <option value="1280x720">
                    16:9 (Large) - 1280 x 720 (HD)
                  </option>
                  <option value="1920x1080">
                    16:9 (Large) - 1920x1080 (Full HD) (peu prendre du temps)
                  </option>
                  <option value="1024x768">4:3 (Standard) - 1024x768</option>
                  <option value="1080x1080">1:1 (Carré) - 1080x1080</option>
                  <option value="2560x1080">
                    21:9 (Ultra-wide) - 2560x1080 (peu prendre du temps)
                  </option>
                  <option value="1080x1920">9:16 (Vertical) - 1080x1920</option>
                </select>

                <br />
                <br />
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="videoFiles"
                      name="videoFiles[]"
                      multiple
                      accept="video/*"
                      onChange={handleFileChange}
                      required
                    />
                    <label className="custom-file-label" htmlFor="videoFiles">
                      {fileNames.length > 0
                        ? fileNames.join(", ")
                        : "Choisissez les fichiers"}
                    </label>
                  </div>
                </div>
              </div>
              <div className="progress mt-3 mb-3" style={{ height: "25px" }}>
                <div
                  id="progressBar"
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {progress}%
                </div>
              </div>
              <button
                id="importation"
                type="submit"
                className="btn btn-block btn-warning btn-flat w-100"
              >
                Importer
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default MontageVideos;
